module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Geschwister Albers","short_name":"Geschwister Albers","start_url":"/","background_color":"#fb8c00","theme_color":"#fb8c00","display":"standalone","icon":"src/images/finales_Logo1024x1024.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bcd47f8621e07a7a070f9f349ddd7368"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
